<template>
  <v-card>
    <v-flex xs12>
      <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
    </v-flex>
    <!-- <v-row>
      <v-col cols="12" class="pt-0"> -->
    <!-- Conteúdo da coluna da direita com rolagem -->

    <v-flex xs12>
      <v-card>
        <v-toolbar color="#333333" dark>
          <v-icon class="mr-2">format_list_numbered</v-icon>
          <v-toolbar-title class="font-weight-light"> Matérias {{ nomeEditoriaSelecionada }} </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn v-if="direitoCriar && liberaAdd" color="success" @click="criarArtigo()" fab small>
            <v-icon title="Incluir">add</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="px-6 mt-4">
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex md6 xs12>
                <v-row>
                  <v-col cols="auto">
                    <v-autocomplete
                      ref="editoriaAutocomplete"
                      v-model="selectedEditoria"
                      :items="registroEditorias"
                      item-text="nome"
                      :item-value="(editoria) => ({ id: editoria.id, nome: editoria.nome, tipo: editoria.tipo })"
                      label="Editoria"
                      no-data-text="Nenhuma editoria disponível"
                      @change="carregarArtigos"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn color="blue" fab small v-if="selectedEditoria != null" @click="pesquisaDados(true)">
                      <v-icon color="white" title="Atualizar">refresh</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-flex>

              <v-flex md6 xs12>
                <v-text-field
                  background-color="white"
                  class="mt-2 mb-2 elevation-0"
                  v-model="filtroPesquisa"
                  append-icon="search"
                  label="Digite para pesquisar..."
                  placeholder="Pesquisa pelo nome"
                  hide-details
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>

          <!--   <div ref="scrollContainer" style="height: 50vh; overflow-y: auto;"> -->
          <v-data-table
            ref="dataTable"
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            hide-default-footer
            :disable-sort="carregando"
            :disable-pagination="carregando"
            show-expand
          >
            <template v-slot:item="{ item, index }">
              <tr @click="selecionarItem(item)" :class="['text-left', index % 2 === 0 ? 'linha-clara linha-destaque' : 'linha-escura linha-destaque']" class="text-left principal">
                <td class="btns-acoes">
                  <v-icon class="v-icon-list" v-if="direitoCriar && idArtigoaberto === item.idMateria" @click="toggleExpand(item.idMateria)">
                    {{ isExpanded(item.idMateria) ? "mdi-chevron-up" : "mdi-chevron-down" }}
                  </v-icon>

                  <v-icon
                    class="v-icon-list"
                    v-if="direitoCriar && idArtigoaberto === item.idMateria && !edtLixeiraControl && liberaAdd"
                    color="blue"
                    title="Editar"
                    @click="editarArtigo(item.idMateria)"
                    >edit</v-icon
                  >

                  <v-icon
                    class="v-icon-list"
                    v-if="direitoCriar && idArtigoaberto === item.idMateria && !edtLixeiraControl && liberaAdd"
                    color="blue"
                    title="Renomear Matéria"
                    @click="fnAlteraNome(item)"
                    >title</v-icon
                  >

                  <v-icon
                    class="v-icon-list"
                    v-if="direitoCriar && idArtigoaberto === item.idMateria && !edtLixeiraControl"
                    color="blue"
                    title="Preview"
                    @click="fnModalHtmlContent(item.idMateria)"
                    >visibility</v-icon
                  >

                  <v-icon
                    class="v-icon-list"
                    v-if="direitoCopiar && !item.filhas && idArtigoaberto === item.idMateria && !edtLixeiraControl && liberaAdd"
                    color="blue"
                    title="Copiar Matéria"
                    @click="mostrarModalCopiar = true"
                    >content_copy</v-icon
                  >

                  <v-icon
                    class="v-icon-list"
                    v-if="direitoMover && idArtigoaberto === item.idMateria && !edtLixeiraControl && liberaAdd"
                    color="blue"
                    title="Mover Matéria"
                    @click="modalMoveMateria = true"
                    >drive_file_move</v-icon
                  >

                  <v-icon
                    class="v-icon-list"
                    v-if="direitoCriar && idArtigoaberto === item.idMateria && !edtLixeiraControl"
                    color="blue"
                    title="Histórico"
                    @click="mostrarModalHistorico"
                    >history</v-icon
                  >

                  <v-icon
                    class="v-icon-list"
                    v-if="direitoExcluir && idArtigoaberto === item.idMateria && !edtLixeiraControl"
                    color="error"
                    title="Remover"
                    @click="dialogConfirmacao = true"
                    >delete</v-icon
                  >

                  <!-- EDITORIA LIXEIRA -->
                  <v-icon class="v-icon-list" v-if="direitoCriar && edtLixeiraControl" color="blue" title="Restaurar Matéria" @click="fnRestaurarArtigo(item.idMateria)"
                    >replay</v-icon
                  >
                </td>
                <td>{{ item.nomeMateria }}</td>
                <td>{{ item.versao }}</td>
                <td>{{ item.autorMateria }}</td>
                <td>{{ item.dataCriacao }}</td>
                <td>{{ item.dataPublicacao }}</td>
              </tr>
              <!-- Renderiza a tabela expandida apenas se a linha estiver expandida -->
              <tr v-if="isExpanded(item.idMateria)" class="sub-tabela text-left">
                <td colspan="12">
                  <!-- Tabela interna (Expandida) -->
                  <v-data-table :headers="subHeaders" :items="getExpandedData(item.idMateria)" dense hide-default-footer class="mt-3">
                    <template v-slot:item="{ item }">
                      <tr @click="selecionarSubItem(item.idConteudo)">
                        <td>
                          <v-icon
                            v-if="direitoCriar && idArtigoaberto === item.idMateria && !edtLixeiraControl"
                            color="blue"
                            title="Editar"
                            @click="editarArtigoVersao(item.idMateria, item.idConteudo)"
                            >edit</v-icon
                          >

                          <v-icon
                            class="v-icon-list"
                            v-if="direitoCriar && idArtigoaberto === item.idMateria && !edtLixeiraControl"
                            color="blue"
                            title="Preview"
                            @click="fnModalHtmlContent(item.idMateria)"
                            >visibility</v-icon
                          >
                        </td>
                        <td>{{ item.dataVersao }}</td>
                        <td>{{ item.dataCriacao }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </td>
              </tr>
            </template>
          </v-data-table>
          <!--   </div> -->
        </div>
        <!-- <div class="text-center pt-2 marg-bt">
                       <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
              </div> -->

        <v-snackbar v-model="snackbar" color="info" :timeout="5000" bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" bottom left>{{ mensagem }}</v-snackbar>
      </v-card>
    </v-flex>
    <!-- <v-divider></v-divider> -->

    <!-- Modal Copiar Artigo -->
    <v-dialog v-model="mostrarModalCopiar" max-width="800px">
      <v-card>
        <v-card-title class="headline">Copiar Matéria</v-card-title>
        <v-card-text>
          <v-alert v-if="tituloErro" type="error" prominent icon="mdi-alert-circle-outline">
            {{ mensagem }}
          </v-alert>
          <v-form @submit.prevent>
            <v-text-field ref="nomeArtigoInput" v-model="newArtigoCopia.novoNome" label="Nome da Matéria"></v-text-field>
            <v-autocomplete
              v-model="selectedEditoriaCopia"
              :items="filteredEditoriasCopia"
              item-text="nome"
              :item-value="(copiaEditoria) => ({ id: copiaEditoria.id, nome: copiaEditoria.nome, tipo: copiaEditoria.tipo })"
              label="Editoria"
              no-data-text="Nenhuma editoria disponível"
              @change="newArtigoEditoriaId"
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="mostrarModalCopiar = false" color="orange lighten-2 white" id="btn-rsn" style="color: white">
            <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
          </v-btn>

          <v-btn :loading="isUpdating" color="blue white" id="btn-rsn" style="color: white" depressed @click="salvarCopiaArtigo()">
            <v-icon id="icon-rsn" left>update</v-icon>Copiar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Copiar Artigo -->

    <!-- Modal Histórico -->
    <v-dialog v-model="mostrarHistorico" max-width="800px">
      <v-card>
        <v-card-title class="headline">Histórico</v-card-title>
        <v-card-text>
          <template>
            <v-data-table :headers="headersHistorico" :items="registroVisualizarHistorico">
              <template v-slot:[`item.operacao`]="{ item }">
                {{ getOperacaoNome(item.operacao) }}
              </template>

              <!-- Coluna Editoria Origem -->
              <template v-slot:[`item.idEditoriaOrigem`]="{ item }">
                {{ getEditoriaNomeHistorico(item.idEditoriaOrigem) }}
              </template>

              <!-- Coluna Nome Original -->
              <template v-slot:[`item.nomeOriginal`]="{ item }">
                {{ item.nomeOriginal }}
              </template>

              <!-- Coluna Novo Nome -->
              <template v-slot:[`item.novoNome`]="{ item }">
                {{ item.novoNome }}
              </template>
            </v-data-table>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="mostrarHistorico = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Histórico -->

    <!-- MODAL MOVE MATÉRIA -->
    <v-dialog v-model="modalMoveMateria" max-width="800px">
      <v-card>
        <v-card-title class="headline">Mover Matéria</v-card-title>
        <v-card-text>
          <v-alert v-if="tituloErro" type="error" prominent icon="mdi-alert-circle-outline">
            {{ mensagem }}
          </v-alert>
          <v-form @submit.prevent>
            <v-text-field v-model="this.newArtigoCopia.novoNome" disabled></v-text-field>
            <v-autocomplete
              v-model="selectedEditoriaMove"
              :items="filteredEditoriasMove"
              item-text="nome"
              item-value="id"
              label="Editoria"
              no-data-text="Nenhuma editoria disponível"
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="modalMoveMateria = false" color="orange lighten-2 white" id="btn-rsn" style="color: white">
            <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
          </v-btn>

          <v-btn :loading="isUpdating" color="blue white" id="btn-rsn" style="color: white" depressed @click="fnMoverArtigo()">
            <v-icon id="icon-rsn" left>update</v-icon>Mover
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MODAL MOVE MATÉRIA -->

    <!-- MODAL AVISOS -->
    <v-dialog v-model="dialogAviso" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>
          {{ mensagemAviso }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogAviso = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MODAL AVISOS -->

    <!-- MODAL CONFIRMA LIXEIRA -->
    <v-dialog v-model="dialogConfirmacao" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmação</v-card-title>
        <v-card-text> Deseja mover essa matéria para a lixeira? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogConfirmacao = false">Cancelar</v-btn>
          <v-btn color="red" text @click="moverParaLixeira">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MODAL CONFIRMA LIXEIRA -->

    <!-- MODAL HTML CONTENT PREVIEW -->
    <v-dialog v-model="modalHtmlContent" max-width="600px">
      <v-card>
        <v-toolbar color="#333333">
          <v-toolbar-title style="color: white" class="font-weight-light headline">{{ newArtigoCopia.novoNome }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="modalHtmlContent = false">
            <v-icon color="white">cancel</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <!-- Elemento com o conteúdo HTML dinâmico -->
          <div class="output-area pa-6">
            <div v-html="htmlContent" class="output-field"></div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="modalHtmlContent = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MODAL HTML CONTENT PREVIEW -->

    <!-- MODAL TITULO NOVO ARTIGO -->
    <v-dialog v-model="abreModalTitle" max-width="800px" @keydown.enter="salvar">
      <v-card>
        <v-toolbar color="#333333">
          <v-toolbar-title style="color: white" class="font-weight-light">{{ nomeModal }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="abreModalTitle = false">
            <v-icon color="white">cancel</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-card-text>
            <v-alert v-if="tituloErro" type="error" prominent icon="mdi-alert-circle-outline">
              {{ mensagemAviso }}
            </v-alert>
            <v-form @submit.prevent>
              <v-text-field
                autofocus
                v-model="nomeArtigo"
                label="Nome Matéria"
                maxlength="300"
                hint="Obrigatório"
                color="blue-grey lighten-2"
                autocomplete="disabled"
                :name="Math.random()"
                @change="
                  nomeArtigo = pLetra(nomeArtigo);
                  validacao.nome = false;
                "
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-card-text>
            <v-form @submit.prevent>
              <!-- Seleção de Grupo de Estilos -->
              <v-autocomplete
                v-if="operacaoSalvar == 1"
                v-model="selectedGrupoModelo"
                :items="registroGruposModelos"
                :item-value="(grupoModelo) => ({ id: grupoModelo.id })"
                item-text="nome_modelo"
                label="Estilos de modelos"
                no-data-text="Nenhum estilo de modelo foi selecionado"
                @change="fnRecebeGrupoModelo"
              ></v-autocomplete>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="salvar">Salvar</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <!-- MODAL TITULO NOVO ARTIGO -->

    <!-- <div></div> -->
    <!-- </v-col>
    </v-row> -->
  </v-card>
</template>

<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import util from "../../util.js";
import { EventBus } from "@/eventBus.js";

export default {
  data() {
    return {
      headers: [
        { text: "", value: "data-table-expand" },
        { text: "Nome da Matéria", value: "nomeMateria" },
        { text: "Versão", value: "versao" },
        { text: "Autor", value: "autorMateria" },
        { text: "Data Criação", value: "dataCriacao" },
        { text: "Data Publicação", value: "dataPublicacao" }
      ],

      /* sub tabela */
      subHeaders: [
        { text: "Ações", value: "actions" },
        { text: "Data Versão", value: "dataVersao" },
        { text: "Data de Criação", value: "dataCriacao" }
      ],
      registrosConteudoVersoes: [],
      expandedRow: null, // Controla a linha expandida, // Para controlar as linhas expandidas
      /* sub tabela */

      headersHistorico: [
        { text: "Operação", value: "operacao" },
        { text: "Nome Original", value: "nomeOriginal" },
        { text: "Editoria Origem", value: "idEditoriaOrigem" },
        { text: "Novo Nome", value: "novoNome" },
        { text: "Nova Editoria", value: "editoriaDestino" },
        { text: "Data Operação", value: "dataOperacao" },
        { text: "Realizado por", value: "realizadoPor" }
      ],

      // rodape: {
      //   itemsPerPageText: "Registros por página:",
      //   itemsPerPageOptions: [100],
      //   pageText: `{0}-{1} de {2}` // Texto do rodapé mostrando a paginação
      // },
      validacao: {
        id: false,
        idSite: false,
        idSuperior: false,
        ordem: false,
        nome: false,
        aliasInternet: false,
        tempoLimpeza: false,
        tipo: false,
        situacao: false,
        mensagemErro: false
      },
      nameEditoria: "",
      direitoCriar: false,
      direitoExcluir: false,
      direitoMover: false,
      direitoCopiar: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 50,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      mensagem: "",
      elementoValid: true,
      id: "",
      snackbar: false,
      snackErro: false,
      isUpdating: false,
      options: {},
      mostrarHistorico: false, // Controla a visibilidade do modal

      registroEditorias: [],
      selectedEditoria: null,
      registroEditoriasFilt: [],
      registroEditoriasCopia: [],

      mostrarModalCopiar: false, //Modal copiar
      edtLixeiraControl: false,
      tituloErro: false,
      idArtigoaberto: "",

      dialogAviso: false,
      mensagemAviso: "",
      itemIdSelecionado: null, // ID do item selecionado
      registrosIndex: [], // Array para armazenar os índices dos registros

      idEditoriaSelecionada: "",
      nomeEditoriaSelecionada: "",
      tipoEditoriaSelecionada: "",
      liberaAdd: false,
      registroVisualizarTexto: "",
      registroVisualizarHistorico: [],

      htmlContent: "",
      modalHtmlContent: false,

      dialogConfirmacao: false, // Variável para controlar o diálogo

      //SALVAR CRIAR
      abreModalTitle: false,
      nomeModal: "",
      nomeArtigo: "",
      newArtigo: {
        idMateria: "0",
        idEditoria: "",
        idUsuario: this.$store.state.usuario.id,
        nome: "",
        altura: "0",
        conteudo: "",
        idMateriaAnterior: "0",
        dtCriacao: "",
        corrigida: "0",
        autoSave: "0",
        renomearMateria: "0"
      },
      operacaoSalvar: "0", // operacao == 0 null / operacao == 1 Novo artigo / operacao == 2 Altera nome /

      //MODELO MATÉRIA
      selectedGrupoModelo: null,
      registroGruposModelos: [],
      idGrupoModelo: null,
      //MODELO MATÉRIA

      //MOVE MATÉRIA
      modalMoveMateria: false,
      selectedEditoriaMove: null,
      //MOVE MATÉRIA

      //SALVAR CRIAR
      selectedEditoriaCopia: null,
      idEditoriaDestinoTipo: "",
      newArtigoCopia: {
        idMateria: "0",
        idEditoriaDestino: "",
        idUsuario: this.$store.state.usuario.id,
        novoNome: "",
        origemCopia: 0,
        altura: "0"
      },
      isUpdating: false,
      idSitePrincipal: this.$store.state.usuario.idSitePrincipal, // TODO AJUSTAR ID SITE - ID USUÁRIO

      registrosPorPagina: 10, // Número inicial de registros por página
      hasMore: true, // Indica se há mais dados para carregar

      tiposHistoricoOperacao: [
        { id: 1, nome: "Destravada" },
        { id: 2, nome: "Editada" },
        { id: 3, nome: "Criada" },
        { id: 4, nome: "Alterada" },
        { id: 5, nome: "Copiada" },
        { id: 6, nome: "Movida" },
        { id: 7, nome: "Renomeada" },
        { id: 8, nome: "Movida Lixeira" },
        { id: 9, nome: "Restaurada Lixeira" },
        { id: 10, nome: "Matéria Publicada" }
      ],

      //MOVER ARTIGO
      moveArtigo: {
        idMateria: "0",
        idEditoriaDestino: "",
        idUsuario: this.$store.state.usuario.id
      }
      //MOVER ARTIGO
    };
  },

  async mounted() {
    this.carregarEditorias();
    this.fnCarregaModelos();

    //Ouve para receber o elemento sidebar
    EventBus.$on("editoria-selecionada", this.carregarArtigos);

    //Aba recebe foco
    window.addEventListener("focus", this.pesquisaDados);

    // console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
    // console.log(this.registroEditorias.length);
    this.$nextTick(() => {
      this.$refs.editoriaAutocomplete.focus(); // Foco no autocomplete editorias
    });

    this.debouncedHandleScroll = this.debounce(() => this.handleScroll(), 200); //tempo em milisegundos
  },
  updated() {
    window.addEventListener("scroll", this.debouncedHandleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.debouncedHandleScroll);

    window.removeEventListener("focus", this.pesquisaDados);

    //Ouve o elemento sidebar
    EventBus.$off("editoria-selecionada", this.carregarArtigos);
  },

  computed: {
    //FILTRA SITUAÇÃO == 0 EDITORIA LIXEIRA
    filteredEditoriasCopia() {
      return this.registroEditorias.filter((editoria) => editoria.tipo != 2);
    },
    //FILTRA SITUAÇÃO == 0 EDITORIA LIXEIRA

    //FILTRA EDITORIAS PARA MOVER ARTIGO - RETIRA EDITORIA ATUAL - LIXEIRA - PRODUÇÃO
    filteredEditoriasMove() {
      return this.registroEditorias.filter((editoria) => editoria.id != this.idEditoriaSelecionada && editoria.tipo != 2 && editoria.tipo != 3);
    }
    //FILTRA EDITORIAS PARA MOVER ARTIGO
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO", "OCULTAR_MENU"]),
    getItemActions() {
      return "item.actions";
    },
    getItemNumero() {
      return "item.numero";
    },
    getItemActions2() {
      return "item.actions2";
    },
    getItemTipo() {
      return "item.tipo";
    },
    getItemSituacao() {
      return "item.situacao";
    },
    getItemExpand() {
      return "item.data-table-expand";
    },
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados(true);
    },

    // Formata a primeira letra para maiúscula
    pLetra(text) {
      return util.pLetra(text);
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },

    getOperacaoNome(id) {
      const operacao = this.tiposHistoricoOperacao.find((tipo) => tipo.id === id);
      return operacao ? operacao.nome : "Operação Desconhecida";
    },
    getEditoriaNomeHistorico(id) {
      const editoria = this.registroEditorias.find((editoria) => editoria.id === id);
      return editoria ? editoria.nome : "Editoria Desconhecida";
    },
    focusOnInput() {
      const input = this.$refs.nomeArtigoInput.$refs.input; // Acessa o elemento de input interno
      if (input) {
        input.focus(); // Foca no campo
        const length = input.value.length; // Obtém o comprimento do texto
        input.setSelectionRange(length, length); // Posiciona o cursor no final
      }
    },

    /* sub tabela */
    // Filtra os dados da tabela expandida com base no idMateria
    getExpandedData(idMateriaExpand) {
      // console.log("1111111111111111121212");
      return this.registrosConteudoVersoes.filter((registros) => registros.idMateria === idMateriaExpand);
    },
    // Verifica se a linha está expandida
    isExpanded(idMateriaExpand) {
      return this.expandedRow === idMateriaExpand;
    },
    // Alterna a expansão da linha
    toggleExpand(idMateriaExpand) {
      // console.log("33333333333");

      this.expandedRow = this.expandedRow === idMateriaExpand ? null : idMateriaExpand;
    },
    /* sub tabela */

    temSinalELetraMaiuscula() {
      const regex = /<([A-Z])/;
      return regex.test(this.htmlContent);
    },

    fnModalHtmlContent() {
      this.modalHtmlContent = true;
      this.carregando = true;
    },

    async salvarCopiaArtigo() {
      await util.limparObjeto(this.validacao, false);
      if (this.selectedEditoriaCopia == null) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        this.isUpdating = false;
        return;
      }

      // console.log(this.newArtigoCopia), console.log("@@@@@@@@@@@@@@@@@@@@@@@@@");

      // console.log(this.idEditoriaDestinoTipo);
      // console.log("##################");
      // console.log(this.htmlContent);

      if (this.idEditoriaDestinoTipo == 3) {
        //Se for de produção entra aqui
        // console.log("PRODUÇÃOOOOOOOOOOOOOOOOOOOOOOOOO");

        // console.log(this.htmlContent);

        if (this.htmlContent.includes("&lt;") || this.htmlContent.includes("&gt;")) {
          // console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
          // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");

          // console.log(" EXISTE <> PRODUÇÃOOOOOOOOOOOOOOOOOOOOOOOOO");
          this.isUpdating = true;
          try {
            var retorno = "";
            retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "edt/editorial/enviaMateriaProducao", this.newArtigoCopia);

            if (!retorno.data.erro) {
              // console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
              // console.log(retorno.data);
              // console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
              this.mensagem = "Matéria foi copiado";
              if (this.newArtigoCopia.idEditoriaDestino == this.idEditoriaSelecionada) {
                //SE FOR MESMA EDITORIA ATUAL ATUALIZA A TABELA
                this.pesquisaDados(true);
              }
              this.snackbar = true;
              this.mostrarModalCopiar = false;
            } else {
              // console.log("##########################");
              // console.log(retorno.data.descricao);
              // console.log("#########################");
              this.tituloErro = true;
              this.mensagem = retorno.data.descricao;
            }
          } catch (e) {
            // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
            // console.log(e);
            if (e.toString().includes("status code 400")) {
              this.mensagem = "SESSÃO EXPIRADA";
              this.snackErro = true;
            } else if (e.response && e.response.data) {
              util.validaErros(this.validacao, e.response.data);
              this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
              this.snackErro = true;
            } else {
              this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
              this.snackErro = true;
            }
          } finally {
            this.isUpdating = false;
          }
        } else {
          // console.log("NÂO TEMMMMMMMMMMMMMMMMMMMMMMMMM <><><><><><<<");
          this.mensagemAviso = "Falta estilo nessa matéria, ela não pode ser enviada para produção.";
          this.dialogAviso = true;
        }
      } else {
        //Editorias que não são de produção
        // console.log("OUTROSSSSSSSSSSSSSSSSSS");
        this.isUpdating = true;
        try {
          var retorno = "";
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "edt/editorial/copiaMateria", this.newArtigoCopia);

          if (!retorno.data.erro) {
            // console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
            // console.log(retorno.data);
            // console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
            this.mensagem = "Matéria foi copiado";
            if (this.newArtigoCopia.idEditoriaDestino == this.idEditoriaSelecionada) {
              //SE FOR MESMA EDITORIA ATUAL ATUALIZA A TABELA
              this.pesquisaDados(true);
            }

            this.snackbar = true;
            this.mostrarModalCopiar = false;
          } else {
            // console.log("##########################");
            // console.log(retorno.data.descricao);
            // console.log("#########################");
            this.tituloErro = true;
            this.mensagem = retorno.data.descricao;
          }
        } catch (e) {
          // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
          // console.log(e);
          if (e.toString().includes("status code 400")) {
            this.mensagem = "SESSÃO EXPIRADA";
            this.snackErro = true;
          } else if (e.response && e.response.data) {
            util.validaErros(this.validacao, e.response.data);
            this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
            this.snackErro = true;
          } else {
            this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
            this.snackErro = true;
          }
        } finally {
          this.isUpdating = false;
        }
      } //Editoria não produção
    },

    async newArtigoEditoriaId(selectEditoriaCopia) {
      if (selectEditoriaCopia != null) {
        // console.log("ID enviado:", selectEditoriaCopia.id, selectEditoriaCopia.nome, selectEditoriaCopia.tipo, this.selectedEditoriaCopia);
        this.newArtigoCopia.idEditoriaDestino = selectEditoriaCopia.id;
        this.idEditoriaDestinoTipo = selectEditoriaCopia.tipo;
      }
    },

    // ... outras funções ...
    selecionarItem(item) {
      this.pesquisaVersoes();

      this.visualizarTexto(item.idMateria);

      if (item.idMateria != this.itemIdSelecionado) {
        this.expandedRow = null;
      }

      // console.log(item);
      // console.log("#######################################################" + item.nomeMateria);
      this.itemIdSelecionado = item.idMateria;
      this.newArtigoCopia.novoNome = item.nomeMateria;
      this.newArtigoCopia.idMateria = item.idMateria;
      this.registrosIndex = this.registros.map((reg, index) => ({ id: reg.idMateria, index }));
    },

    selecionarSubItem(idConteudo) {
      this.visualizarTextoVersao(idConteudo);
      // console.log(idConteudo);
      // console.log("SUB ITEMMMMMMMMMMMMMM" + idConteudo);
      /*       this.itemIdSelecionado = item.idMateria;
      this.newArtigoCopia.novoNome = item.nomeMateria;
      this.newArtigoCopia.idMateria = item.idMateria;
      this.registrosIndex = this.registros.map((reg, index) => ({ id: reg.idMateria, index })); */
    },
    // ... outras funções ...

    //  ------------------  HISTORICO DATALHES  ------------------------------
    async mostrarModalHistorico() {
      // console.log(this.idArtigoaberto);
      const bodyHistorico = {
        idMateria: this.idArtigoaberto
      };
      //Organiza e exibie as editorias na lateral
      this.registroVisualizarHistorico = []; // Limpa o array antes de buscar os dados
      try {
        // Faz a pesquisa no banco de dados
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/buscaRegistrosMateria", bodyHistorico);
        // Seta as variáveis com o retorno
        this.registroVisualizarHistorico = response.data.registros; // Atribui os dados ao array

        // console.log(response.data.registros, "@@@@@@@@@@@@@@@@@@@");
        this.mostrarHistorico = true; // Mostra o modal
      } catch (error) {
        console.error("Erro ao buscar editorias:", error);
        // Tratar o erro (exibir mensagem ao usuário, etc.)
      }
    },

    async visualizarTexto(idArtigo) {
      const body = {
        idMateria: idArtigo,
        conteudoAcervo: "0"
      };

      //Organiza e exibie as editorias na lateral
      this.registroVisualizarTexto = ""; // Limpa o array antes de buscar os dados
      try {
        // Faz a pesquisa no banco de dados
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/buscaConteudoMateria", body);
        // Seta as variáveis com o retorno
        this.htmlContent = response.data.conteudo.conteudo;

        this.idArtigoaberto = idArtigo; //atualiza o ID do artigo que foi aberto - usado para mostraro histórico
        // console.log(response.data.conteudo);
      } catch (error) {
        console.error("Erro ao buscar editorias:", error);
        // Tratar o erro (exibir mensagem ao usuário, etc.)
      }
      // Converte e exibe texto plano
    },

    async visualizarTextoVersao(idVersao) {
      const body = {
        idConteudo: idVersao
      };

      //Organiza e exibie as editorias na lateral
      this.registroVisualizarTexto = ""; // Limpa o array antes de buscar os dados
      try {
        // Faz a pesquisa no banco de dados
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/buscaConteudoVersao", body);
        // Seta as variáveis com o retorno
        this.htmlContent = response.data.conteudo.conteudo;

        // console.log(response.data.conteudo);
      } catch (error) {
        console.error("Erro ao buscar editorias:", error);
        // Tratar o erro (exibir mensagem ao usuário, etc.)
      }
      // Converte e exibe texto plano
    },

    async carregarEditorias() {
      //Organiza e exibie as editorias na lateral
      this.registroEditorias = []; // Limpa o array antes de buscar os dados

      try {
        // Faz a pesquisa no banco de dados
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editoria/all");
        // Seta as variáveis com o retorno
        this.registroEditorias = response.data; // Atribui os dados ao array
        // console.log(response.data);
        // console.log("!!!!!!!!!!");
        // console.log(JSON.stringify(response.data.data) + "@@@@@@@@@@@@@@@@@@@");
      } catch (error) {
        // console.error("Erro ao buscar editorias:", error);
        // Tratar o erro (exibir mensagem ao usuário, etc.)
      }
      //Organiza e exibie as editorias na lateral
    },

    editarArtigo(idArtigoEdit) {
      window.open("/editorial/artigo/" + this.idEditoriaSelecionada + "/" + idArtigoEdit + "/null/null", "_blank");
      //para listar as parcelas do retorno, teria que buscar as parcelas com join em log_parcela.idParcela e onde log_parcela.idLoteFaturamento = item.id - porém pode ficar muito lento com este join a mais
    },

    editarArtigoVersao(idArtigoEdit, idVersaoEdit) {
      window.open("/editorial/artigo/" + this.idEditoriaSelecionada + "/" + idArtigoEdit + "/" + idVersaoEdit + "/null", "_blank");
      //para listar as parcelas do retorno, teria que buscar as parcelas com join em log_parcela.idParcela e onde log_parcela.idLoteFaturamento = item.id - porém pode ficar muito lento com este join a mais
    },

    async pesquisaDados(reset = false) {
      if (reset) {
        this.registros = []; // Limpa a lista se reset for true
        this.htmlContent = "";
        this.toggleExpand(null);
        // } else {
        //   console.log("TAMANHO ARRAYYYYYYYYYY");
        //   console.log(this.registros.length);
        //   console.log(this.registros);
      }

      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";
      // console.log(this.idEditoriaSelecionada + " $$$$$$$$$$ " + this.nomeEditoriaSelecionada);

      //Fecha modal move se estiver aberto
      this.modalMoveMateria = false;

      // Verifica se a editoria é de lixeira
      let edtLixeira = 0;
      this.edtLixeiraControl = false;
      if (this.tipoEditoriaSelecionada == 2) {
        edtLixeira = 1;
        // console.log("LIXEIRAAAAAAAAAAAAAAAAAAAAAAAAAA");
        this.edtLixeiraControl = true;
      }
      // console.log("this.filtroPesquisa ");
      // console.log(this.filtroPesquisa);
      // console.log("TAMANHO ARRAYYYYYYYYYY");
      // console.log(this.registros.length);

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "edt/editorial/buscaMaterias?idEditoria=" +
            this.idEditoriaSelecionada +
            "&excluida=" +
            edtLixeira +
            "&campoOrdenar=1&ordem=1&versoes=0&buscaQnt=0&inicio=" +
            this.registros.length +
            "&limite=" +
            this.registrosPorPagina +
            "&nomePesquisa=" +
            this.filtroPesquisa
        );

        //seta as variaveis com o retorno

        if (response.data.materias.length > 0) {
          this.hasMore = response.data.materias.length >= this.registrosPorPagina; // Verifica ANTES da filtragem

          // Filtra antes de adicionar
          const novosRegistros = response.data.materias.filter((novoItem) => !this.registros.some((existingItem) => existingItem.idMateria === novoItem.idMateria));
          this.registros.push(...novosRegistros);

          // console.log(response.data.materias);
          // console.log(response.data.materias[0].idMateria);

          if (reset) {
            this.idArtigoaberto = response.data.materias[0].idMateria;
            this.itemIdSelecionado = response.data.materias[0].idMateria;
            this.visualizarTexto(response.data.materias[0].idMateria);
            this.selecionarItem(response.data.materias[0]);
            // console.log("AQUIIIIIIIIIIIIIIII AQUIIIIIIIIIIIIIIIIIIIIIIIIIII");
            // console.log(response.data.materias[0]);
          }

          this.totalRegistros = registros.length;
          this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
          // this.rodape.pageText = this.totalRegistros + " itens";
          //fecha o loading
          this.carregando = false;
        } else {
          this.hasMore = false;
        }
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    debounce(func, delay) {
      let timeoutId;
      return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    handleScroll() {
      if (document.documentElement.scrollHeight <= 0) return; // Evita erros caso não tenha conteúdo na tela
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      /* 
          console.log("scrollY:",scrollY)
         console.log("windowHeight:",windowHeight)
        console.log("documentHeight:",documentHeight) */

      if (scrollY > 0 && scrollY + windowHeight >= documentHeight - 50 && this.hasMore && windowHeight < documentHeight) {
        this.pesquisaDados(false);
      }
    },

    async pesquisaVersoes() {
      this.registrosConteudoVersoes = [];
      //sinaliza que está carregando

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "edt/editorial/buscaMaterias?idMateria=" +
            this.itemIdSelecionado + //id artigo selecionado
            "&excluida=0&campoOrdenar=0&ordem=0&versoes=1"
        );

        //seta as variaveis com o retorno

        if (response.data.materias.length > 0) {
          // Converte o nomeMateria de Base64 para texto legível
          this.registrosConteudoVersoes = response.data.materias;
          // Converte o nomeMateria de Base64 para texto legível
        }
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },

    //Função executado assim que uma editoria é selecionada
    async carregarArtigos(selectEditoria) {
      if (selectEditoria && selectEditoria.id) {
        // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@:");
        // console.log("ID enviado:", selectEditoria.id, selectEditoria.nome, selectEditoria.tipo);

        this.selectedEditoria = selectEditoria; // Atualiza o valor selecionado no v-model
        this.idEditoriaSelecionada = selectEditoria.id;
        this.nomeEditoriaSelecionada = selectEditoria.nome;
        this.tipoEditoriaSelecionada = selectEditoria.tipo;

        this.newArtigo.idEditoria = selectEditoria.id;

        if (selectEditoria.tipo == 2 || selectEditoria.tipo == 3) {
          this.liberaAdd = false;
        } else {
          this.liberaAdd = true;
        }
        this.pesquisaDados(true);
      }
    },
    //Função ao fechar modal de copiar - limpa campos

    //Mover para lixeira
    async moverParaLixeira() {
      // console.log("%%%%%%%%%%%");
      // console.log(this.idArtigoaberto);

      // Se o usuário clicar em "OK", execute a lógica de mover para lixeira
      const bodyLixeira = {
        idMateria: this.idArtigoaberto,
        idUsuario: this.newArtigoCopia.idUsuario
      };
      //Organiza e exibie as editorias na lateral

      try {
        // Transfere para editoria lixeira
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/enviaMateriaLixeira", bodyLixeira);

        // console.log(response.data, "@@@@@@@@@@@@@@@@@@@");

        this.mensagemAviso = "Matéria movido para lixeira";
        this.dialogConfirmacao = false; // Fecha o diálogo
        this.dialogAviso = true;
        this.pesquisaDados(true);
      } catch (error) {
        console.error("Erro ao buscar editorias:", error);
        // Tratar o erro (exibir mensagem ao usuário, etc.)
      }
    },
    //Mover para lixeira

    //Função restaurar artigos
    async fnRestaurarArtigo(idArtigoRestaura) {
      // console.log("%%%%%%%%%%%");
      // console.log(idArtigoRestaura);

      // Se o usuário clicar em "OK", execute a lógica de mover para lixeira
      const bodyRestaura = {
        idMateria: idArtigoRestaura
      };
      //Organiza e exibie as editorias na lateral

      try {
        // Transfere para editoria lixeira
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/restauraMateriaLixeira", bodyRestaura);

        // console.log(response.data, "@@@@@@@@@@@@@@@@@@@");

        this.mensagemAviso = "Artigo foi restaurado!";
        this.dialogConfirmacao = false; // Fecha o diálogo
        this.dialogAviso = true;
        this.pesquisaDados(true);
      } catch (error) {
        console.error("Erro ao buscar editorias:", error);
        // Tratar o erro (exibir mensagem ao usuário, etc.)
      }
    },
    //Função restaurar artigos

    //SALVAR CRIAR

    // Obtém a data e hora atual formatada
    fnDtCriacao() {
      const options = {
        timeZone: "America/Sao_Paulo",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      };
      const dataHoraBrasilia = new Date().toLocaleString("pt-BR", options);
      this.newArtigo.dtCriacao = dataHoraBrasilia.replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$2-$1").replace(",", "");
    },

    // Codifica o nome do artigo para Base64
    codificarNome() {
      this.newArtigo.nome = this.nomeArtigo;
    },

    criarArtigo() {
      this.tituloErro = false;
      this.mensagemAviso = "";
      this.nomeModal = "Nova Matéria";
      this.nomeArtigo = "";
      this.newArtigo.idMateria = "0";
      this.newArtigo.idUsuario = this.$store.state.usuario.id;
      this.newArtigo.conteudo = "";
      this.newArtigo.idMateriaAnterior = "0";
      this.newArtigo.dtCriacao = "";
      this.newArtigo.corrigida = "0";
      this.newArtigo.autoSave = "0";
      this.newArtigo.renomearMateria = "0";
      this.selectedGrupoModelo = null;
      this.idGrupoModelo = null;
      this.operacaoSalvar = 1;
      this.abreModalTitle = true;
    },

    // Verifica se Título foi alterado
    fnAlteraNome(item) {
      this.tituloErro = false;
      this.mensagemAviso = "";
      this.nomeModal = "Renomear Matéria";
      this.newArtigo.idMateria = item.idMateria;
      this.newArtigo.idUsuario = this.$store.state.usuario.id;
      this.nomeArtigo = item.nomeMateria;
      this.newArtigo.conteudo = item.conteudo;
      this.newArtigo.dtCriacao = "";
      this.newArtigo.corrigida = "0";
      this.newArtigo.autoSave = "0";
      this.newArtigo.renomearMateria = "1";
      this.operacaoSalvar = 2;
      this.abreModalTitle = true;
    },

    async salvar() {
      this.isUpdating = true;

      if (this.nomeArtigo == "") {
        this.mensagemAviso = "Verifique os campos indicados em vermelho neste formulário";
        this.abreModalTitle = true;
        this.isUpdating = false;
        this.tituloErro = true;
        return;
      } else {
        this.mensagemAviso = "";
        this.abreModalTitle = false;
        this.tituloErro = false;
      }

      try {
        var retorno = "";

        this.codificarNome(); // Pegar o valor do nome do input

        if (this.operacaoSalvar == 1) {
          // novo
          this.fnDtCriacao();
          // Criação de novo artigo
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/salvaMateria?idSite=" + this.idSitePrincipal, this.newArtigo, {
            headers: {
              "Content-Type": "application/json; charset=utf-8"
            }
          });

          // console.log(retorno.data);
          // console.log(retorno.data.erro);
          // console.log(retorno.data.descricao);

          // retorna ERRO se o título já existe
          if (retorno.data.erro == true) {
            this.mensagemAviso = retorno.data.descricao;
            this.tituloErro = true;
            this.abreModalTitle = true;
            return;
          }

          this.mensagem = "Nova matéria criada.";
          this.snackbar = true;
          this.operacaoSalvar == 0;
          window.open("/editorial/artigo/" + this.idEditoriaSelecionada + "/" + retorno.data.idMateria + "/null/" + this.idGrupoModelo, "_blank");
        } else if (this.operacaoSalvar == 2) {
          // Criação de novo artigo
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/salvaMateria?idSite=" + this.idSitePrincipal, this.newArtigo, {
            headers: {
              "Content-Type": "application/json; charset=utf-8"
            }
          });

          // console.log(retorno.data);
          // console.log(retorno.data.erro);
          // console.log(retorno.data.descricao);

          // retorna ERRO se o título já existe
          if (retorno.data.erro == true) {
            this.mensagemAviso = retorno.data.descricao;
            this.tituloErro = true;
            this.abreModalTitle = true;
            return;
          }
          this.mensagem = "Título alterado.";
          this.snackbar = true;
          this.operacaoSalvar == 0;
        } else {
        }

        this.nomeArtigo = "";
        this.pesquisaDados(true);
      } catch (e) {
        // Tratamento de erros
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    //SALVAR CRIAR

    //CARREGA MODELO ESTILOS
    async fnCarregaModelos() {
      this.registroGruposModelos = [];

      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "edt/editorial/buscaModelosMaterias");
        this.registroGruposModelos = response.data.modelos_materias;
        // console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%");
        // console.log(response.data.modelos_materias);
      } catch (error) {
        console.error("Erro ao buscar grupos de estilos:", error);
      }
    },
    //CARREGA MODELO ESTILOS

    //PEGA ID DO MODELO ESCOLHIDO
    async fnRecebeGrupoModelo(IdSelectGrupoModelo) {
      this.idGrupoModelo = IdSelectGrupoModelo.id;
      // console.log("MODELO MATÉRIA" + this.idGrupoModelo);
    },
    //PEGA ID DO MODELO ESCOLHIDO

    //MOVE ARTIGO
    async fnMoverArtigo() {
      this.moveArtigo.idMateria = this.itemIdSelecionado;
      this.moveArtigo.idEditoriaDestino = this.selectedEditoriaMove;

      // console.log(this.moveArtigo.idMateria + " TRES");
      // console.log(this.moveArtigo.idEditoriaDestino + " DOIS");

      if (this.selectedEditoriaMove == null) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        this.isUpdating = false;
        return;
      }
      this.isUpdating = true;

      try {
        var retorno = "";
        retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "edt/editorial/moveMateria", this.moveArtigo);

        if (!retorno.data.erro) {
          // console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
          // console.log(retorno.data);
          // console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
          this.mensagem = "Matéria foi movido";

          this.snackbar = true;
          this.modalMoveMateria = false;
          this.selectedEditoriaMove = null;
          this.pesquisaDados(true);
        } else {
          // console.log("##########################");
          // console.log(retorno.data.descricao);
          // console.log("#########################");
          this.tituloErro = true;
          this.mensagem = retorno.data.descricao;
        }
      } catch (e) {
        // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
        // console.log(e);
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    }
    //MOVE ARTIGO
  },

  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "EDITORIAL_MATÉRIA_ACESSO"]);
    //* Verifica se o usuário logado possui direito para incluir
    this.$store.state.usuario.direitos.forEach((element) => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "EDITORIAL_MATÉRIA_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "EDITORIAL_MATÉRIA_EXCLUIR") {
        this.direitoExcluir = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "EDITORIAL_MATÉRIA_MOVER") {
        this.direitoMover = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "EDITORIAL_MATÉRIA_COPIAR") {
        this.direitoCopiar = true;
      }
    });
  },

  watch: {
    mostrarModalCopiar() {
      //altera as variáveis quando altera o valor de mostrarModalCopiar
      if (!this.mostrarModalCopiar) this.newArtigoCopia.novoNome = "";
      this.selectedEditoriaCopia = null;
      this.tituloErro = false;
    },

    modalMoveMateria() {
      //altera as variáveis quando altera o valor de modalMoveMateria
      if (!this.modalMoveMateria) this.moveArtigo.idEditoriaDestino = "";
      this.selectedEditoriaMove = null;
      this.tituloErro = false;
    },
    $route() {
      this.paginaAtual = 1;
      //this.pesquisaDados();
    },
    paginaAtual() {
      //this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        // this.pesquisaDados();
      }
    }
  }
};
</script>

<style>
td.btns-acoes {
  display: flex;
  margin-top: 7px !important;
}
.v-application p {
  margin-bottom: 0px !important;
}
img.img-artigo {
  max-width: 320px !important;
  width: 100% !important;
  height: auto !important;
}

.treeview-container {
  height: calc(100vh - 64px); /* Ajuste a altura descontando a toolbar */
  overflow-y: auto;
}
.app-box {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.v-toolbar__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Estilo para as linhas principais */
.linha-clara {
  background-color: #f9f9f9; /* Cor clara para linhas ímpares */
}

.linha-escura {
  background-color: #ececec; /* Cor escura para linhas pares */
}

.linha-destaque:hover {
  background-color: #d1e7dd; /* Cor ao passar o mouse sobre a linha */
}

/* Estilo para os ícones */
.v-icon-list {
  margin-right: 5px;
  cursor: pointer;
}

.v-icon-list:hover {
  transform: scale(1.1); /* Efeito de aumento ao passar o mouse */
}

/* Estilo para a sub-tabela expandida */
.sub-tabela {
  background-color: #ffffff; /* Cor de fundo diferenciada para sub-tabela */
  border-top: 2px solid #007bff; /* Linha superior para destaque */
  padding: 10px; /* Espaçamento para sub-tabela */
}

/* Estilo para as linhas da sub-tabela */
.sub-tabela tr {
  background-color: #fff; /* Fundo branco para linhas da sub-tabela */
}

/* Estilo para as células da sub-tabela */
.sub-tabela td {
  padding: 8px 12px; /* Espaçamento interno das células */
  border-bottom: 1px solid #ddd; /* Linha divisória */
}
.principal td {
  padding: 6px !important; /* Ajuste o valor conforme necessário */
}
tr.sub-tabela td div {
  margin-bottom: 20px !important;
  margin-top: -10px;
}
</style>
