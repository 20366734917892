<template>

<div v-if="this.$route.path.indexOf('/arq') === -1">

  <div v-if="this.$route.path.indexOf('/artigo/') === -1">
    <v-navigation-drawer fixed :clipped="$vuetify.breakpoint.mdAndUp" app v-model="$store.state.drawer">
      <a target="_blank" class="estilo-link-site" href="https://suita.com.br">
        <img class="logo-suita mt-4" src="/suitalogo.png" />
      </a>
      <v-btn class="mt-4" fab fixed right small @click.stop="MOSTRAR_OCULTAR_MENU">
        <v-icon color="black">navigate_before</v-icon>
      </v-btn>

      <v-list dense>
        <template v-for="item in $store.getters.filtroMenu">
          <v-list-group v-if="item.children" v-model="item.model" :key="item.text" :prepend-icon="item.model ? item.icon : item['icon-alt']" append-icon>
            <v-list-item absolute fixed slot="activator">
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ item.text }}</strong>
                </v-list-item-title>
                <!-- itens/títulos com subitens -->
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-for="(child, i) in item.children" :key="i" :to="child.acesso ? child.link : ''">
              <v-list-item-action v-if="child.icon" class="ml-4">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="color: black">{{ ajustaTexto(child.text) }}</v-list-item-title>
                <!-- subitens -->
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item v-else :key="item.text + '-else'" :to="item.acesso ? item.link : ''">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title style="color: black">{{ item.text }}</v-list-item-title>
              <!-- itens sem subitens -->
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <div v-if="this.$route.path.indexOf('/artigos') > -1">
        <template>
          <v-data-table :items="registrosSidebar" :headers="headers" item-value="id" class="elevation-0" hide-default-footer hide-default-header no-data-text :footer-props="rodape">
            <template #item="{ item, index }">
              <tr draggable="true" @dragstart="onDragStart($event, index)" @dragover.prevent @drop="onDrop($event, index)" @click="carregarArtigos(item)">
                <td style="max-width: 20px">
                  <v-icon>{{ getIcon(item.tipo) }}</v-icon>
                </td>
                <td>{{ item.nome }}</td>
              </tr>
            </template>
          </v-data-table>
        </template>


        <!-- <template>
          <v-list>
            <v-list-item v-for="item in registrosSidebar" :key="item.id" @click="carregarArtigos(item)">
              <v-list-item-action>
                <v-icon>{{ getIcon(item.tipo) }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="color: black">
                  {{ item.nome }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template> -->
      </div>
    </v-navigation-drawer>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </div>
</div>
</template>

<script>
import { mapMutations } from "vuex";
import { EventBus } from "@/eventBus.js";
import { api } from "@/services/api.js";

export default {
  data() {
    return {
      headers: [{ text: "", value: "nome" }],

      modulo: "",
      registrosSidebar: [],
      draggedItemIndex: null,

      mensagem: "",
      snackErro: false,
      rodape: { itemsPerPageOptions: [-1] }
    };
  },
  methods: {
    ...mapMutations(["MOSTRAR_OCULTAR_MENU"]),
    ajustaTexto(texto) {
      var direitoAdmin = false;
      if (this.$store.state.usuario.direitos.length > 0) {
        const item = this.$store.state.usuario.direitos[0];
        direitoAdmin = item.modulo + "_" + item.grupo + "_" + item.direito == "WEBSUITA_ADMIN_SUITA";
      }
      var retorno = texto;
      if (texto == "Ativo" && !this.$store.state.usuario.site_planoContas && !direitoAdmin) {
        retorno = "Caixa & Bancos";
      }
      return retorno;
    },

    getIcon(tipo) {
      // Mapeamento de ícones com base no tipo
      const icons = {
        0: "edit_note",
        1: "public",
        2: "delete",
        3: "settings",
        4: "person"
      };
      return icons[tipo]; // Ícone padrão
    },

    carregarArtigos(item) {
      EventBus.$emit("editoria-selecionada", item); // Emite o evento
    },

    async carregarEditoriasSidebar() {
      //Organiza e exibie as editorias na lateral
      this.registrosSidebar = []; // Limpa o array antes de buscar os dados

      try {
        // Faz a pesquisa no banco de dados
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editoria/all");
        // Seta as variáveis com o retorno
        this.registrosSidebar = response.data; // Atribui os dados ao array
        console.log(this.registrosSidebar);
      } catch (error) {
        console.error("Erro ao buscar editorias:", error);
        // Tratar o erro (exibir mensagem ao usuário, etc.)
      }
      //Organiza e exibie as editorias na lateral
    },

    onDragStart(event, index) {
      this.draggedItemIndex = index;
      event.dataTransfer.effectAllowed = "move";
    },
    async onDrop(event, targetIndex) {
      try {
        const draggedIndex = this.draggedItemIndex;
        if (draggedIndex !== null) {
          const draggedItem = this.registrosSidebar.splice(draggedIndex, 1)[0];
          this.registrosSidebar.splice(targetIndex, 0, draggedItem);
          this.draggedItemIndex = null;
          await api.put(process.env.VUE_APP_URL_WEB_API + "edt/acessos-editorias/ordem", this.registrosSidebar);
        }
      } catch (e) {
        this.mensagem = e.response && e.response.data && e.response.data.length > 0 ? e.response.data[0].mensagem : "Indeterminado, verifique sua conexão";
        this.snackErro = true;
        this.carregarEditoriasSidebar();
      }
    }
  },

  async mounted() {
    if (this.$route.path.includes("/editorial/artigos")) {
      this.carregarEditoriasSidebar();
    }
  },
  watch: {
    $route() {
      if (this.$route.path.includes("/editorial/artigos")) {
        this.carregarEditoriasSidebar();
      }
    }
  }
};
</script>

<style>
.primary--text {
  color: #000 !important;
  caret-color: #000 !important;
}
.v-data-table td {
  border: hidden !important;
}
</style>
