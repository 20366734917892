<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">flip</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">Edição</v-toolbar-title>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs6>
            <v-card class="pt-3" min-height="580">
              <v-flex offset-xs3 xs6>
                <v-menu ref="mnuDtEdicao" v-model="mnuDtEdicao" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      autofocus
                      v-model="dtEdicao"
                      label="Data Edição"
                      v-mask="'##/##/####'"
                      hint="Obrigatório"
                      :rules="[validacao.edicaoData == false || validacao.edicaoData, (value) => valida_data(value) || 'Data inválida', (value) => !!value || 'Obrigatório.']"
                      :disabled="isUpdating || id != ''"
                      prepend-icon="event"
                      v-on="on"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @keyup="dtEdicaoUS = valida_data(dtEdicao) ? formatDateUS(dtEdicao) : null"
                      @change="validacao.edicaoData = false"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dtEdicaoUS" locale="pt-br" no-title @input="mnuDtEdicao = false"></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex offset-xs3 xs6>
                <v-text-field
                  background-color="white"
                  v-model="edicao.edicaoNome"
                  :rules="[validacao.edicaoNome == false || validacao.edicaoNome, (value) => valida_nome(value) || 'Nome inválido', (value) => !!value || 'Obrigatório.']"
                  label="Nome"
                  maxlength="35"
                  :disabled="isUpdating || id != ''"
                  color="blue-grey lighten-2"
                  @change="buscaNumeroEdicao"
                  autocomplete="disabled"
                  :name="Math.random()"
                ></v-text-field>
              </v-flex>

              <v-flex offset-xs3 xs6>
                <v-text-field
                  background-color="white"
                  v-model="edicao.edicaoNumero"
                  v-mask="'##########'"
                  :rules="[validacao.edicaoNumero == false || validacao.edicaoNumero, (value) => !!value || 'Obrigatório.']"
                  label="Número"
                  maxlength="35"
                  :disabled="isUpdating || id != ''"
                  color="blue-grey lighten-2"
                  @change="validacao.edicaoNumero = false"
                  autocomplete="disabled"
                  :name="Math.random()"
                ></v-text-field>
              </v-flex>

              <v-flex offset-xs3 xs6>
                <v-autocomplete
                  v-model="edicao.edicaoStatus"
                  :disabled="isUpdating || !editar"
                  :items="status"
                  hint="Obrigatório"
                  :rules="[(value) => parseInt(value) >= 0 || 'Obrigatório.']"
                  :filter="filtrarObjetos"
                  label="Status Publicação"
                  item-text="nome"
                  item-value="id"
                  autocomplete="disabled"
                  :name="Math.random()"
                  no-data-text
                  @change="defineDtEntrada"
                ></v-autocomplete>
              </v-flex>

              <v-flex offset-xs3 xs6 v-if="edicao.edicaoStatus == 1 || edicao.edicaoStatus == 2">
                <v-menu ref="mnuDtEntrada" v-model="mnuDtEntrada" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dtEntrada"
                      label="Data Publicação"
                      v-mask="'##/##/####'"
                      hint="Obrigatório"
                      :rules="[
                        validacao.edicaoDataEntrada == false || validacao.edicaoDataEntrada,
                        (value) => valida_data(value) || 'Data inválida',
                        (value) => !!value || 'Obrigatório.'
                      ]"
                      :disabled="isUpdating || !editar"
                      prepend-icon="event"
                      v-on="on"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @keyup="dtEntradaUS = valida_data(dtEntrada) ? formatDateUS(dtEntrada) : null"
                      @change="validacao.edicaoDataEntrada = false"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dtEntradaUS" locale="pt-br" no-title @input="mnuDtEntrada = false"></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex offset-xs3 xs6 v-if="edicao.edicaoStatus == 1 || edicao.edicaoStatus == 2">
                <v-menu
                  ref="mnuHsEntrada"
                  v-model="mnuHsEntrada"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="hsEntrada"
                      label="Hora Publicação"
                      prepend-icon="mdi-clock-time-four-outline"
                      :disabled="isUpdating || !editar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      autocomplete="disabled"
                      :name="Math.random()"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-if="mnuHsEntrada" v-model="hsEntradaUS" full-width format="24hr"></v-time-picker>
                </v-menu>
              </v-flex>

              <v-flex offset-xs3 xs6 v-if="$store.state.usuario.site_publicacaoLegal">
                <v-switch
                  class="mt-6"
                  v-model="edicao.publicacaoLegal"
                  :rules="[validacao.publicacaoLegal == false || validacao.publicacaoLegal]"
                  :label="`${edicao.publicacaoLegal ? 'Publicação Legal' : 'Não é publicação legal'}`"
                  :disabled="isUpdating || !editar"
                ></v-switch>
              </v-flex>

              <v-flex offset-xs3 xs6 v-if="edicao.publicacaoLegal">
                <v-text-field
                  background-color="white"
                  v-model="edicao.cnpj"
                  :rules="[validacao.cnpj == false || validacao.cnpj, (value) => valida_cnpj(value), (value) => !!value || 'Obrigatório.']"
                  label="CNPJ"
                  v-mask="'##############'"
                  :disabled="isUpdating || !editar"
                  color="blue-grey lighten-2"
                  @change="validacao.cnpj = false"
                  autocomplete="disabled"
                  :name="Math.random()"
                ></v-text-field>
              </v-flex>
              <v-flex offset-xs3 xs6>
                <v-text-field
                  background-color="white"
                  v-model="edicao.paginasTotal"
                  :rules="[validacao.paginasTotal == false || validacao.paginasTotal, (value) => !!value || 'Obrigatório.']"
                  label="Total de Páginas da Edição"
                  v-mask="'##'"
                  :disabled="isUpdating || !editar"
                  color="blue-grey lighten-2"
                  @change="validacao.paginasTotal = false"
                  autocomplete="disabled"
                  :name="Math.random()"
                ></v-text-field>
              </v-flex>

              <v-flex offset-xs3 xs6 v-if="edicao.assinada">
                <v-alert :value="true" color="success" icon="check_circle" outlined>Edição ASSINADA.</v-alert>
              </v-flex>

              <form @submit.prevent="onSubmit" enctype="multipart/form-data" v-if="edicao.arquivosIndividuais && editar">
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-file-input
                        v-model="files"
                        ref="file"
                        label="Selecione arquivos PDF"
                        multiple
                        chips
                        :rules="[validacao.files == false || validacao.files]"
                        accept="application/pdf"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip v-if="index < 8" label small>
                            {{ text }}
                          </v-chip>

                          <span v-else-if="index == 8" class="text-overline grey--text text--darken-3 mx-2"> +{{ files.length - 8 }} arquivos(s) </span>
                        </template>
                      </v-file-input>
                    </v-flex>
                  </v-layout>
                </v-container>
              </form>
            </v-card>
          </v-flex>

          <v-flex offset-xs1 xs5>
            <v-card>
              <v-data-table
                mobile-breakpoint="0"
                :headers="headers"
                :items="paginas"
                no-data-text
                hide-default-footer
                :items-per-page="60"
                height="656"
                fixed-header
                disable-sort
                disable-pagination
              >
                <template v-slot:item.paginasNumero="{ item }">
                  <v-badge :content="item.paginasNumero"></v-badge>
                </template>

                <template v-slot:item.imagem="{ item }">
                  <div style="padding: 8px">
                    <v-img :src="item.imagem" width="117" height="146" style="margin: auto; display: block"></v-img>
                  </div>
                </template>

                <template v-slot:[getItemActions()]="{ item }">
                  <v-icon v-if="edicao.arquivosIndividuais && editar" color="orange" title="Mover para cima" @click="subir(item)">arrow_upward</v-icon>
                  <v-icon v-if="edicao.arquivosIndividuais && editar" color="orange" title="Mover para baixo" @click="descer(item)">arrow_downward</v-icon>
                  <v-icon v-if="edicao.arquivosIndividuais && editar" color="error" title="Excluir" @click="excluir(item)">delete</v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/flip/edicoes" id="btn-rsn" color="orange lighten-2 white" style="color: white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn v-if="editar" :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color: white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn
        v-if="$store.state.usuario.site_safeWeb && id != '' && paginas.length > 0 && !edicao.assinada"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="green white"
        style="color: white"
        id="btn-rsn"
        depressed
        @click="assinar"
      >
        <v-icon id="icon-rsn" left>verified_user</v-icon>Assinar
      </v-btn>
      <v-btn v-if="editar" :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color: white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color: white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar && editar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Nova
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      edicao: {
        _id: "",
        edicaoStatus: 0,
        edicaoNumero: "",
        edicaoNome: "",
        edicaoDataEntrada: "",
        edicaoData: "",
        cnpj: "",
        arquivosIndividuais: 1,
        publicacaoLegal: false,
        paginasTotal: 0,
        Paginas: []
      },
      validacao: {
        edicaoStatus: false,
        edicaoNumero: false,
        edicaoNome: false,
        edicaoDataEntrada: false,
        edicaoData: false,
        cnpj: false,
        paginasTotal: false,
        publicacaoLegal: false,
        files: false,
        mensagemErro: false
      },

      headers: [
        { text: "Número", value: "paginasNumero" },
        { text: "Página", value: "imagem", align: "center" },
        { text: "Ações", value: "actions", width: "200" }
      ],
      paginas: [],

      dtEdicao: null,
      dtEdicaoUS: null,
      mnuDtEdicao: false,

      dtEntrada: null,
      dtEntradaUS: null,
      mnuDtEntrada: false,

      hsEntrada: null,
      hsEntradaUS: null,
      mnuHsEntrada: false,

      status: [
        { id: 0, nome: "Não Publicar" },
        { id: 1, nome: "Agendar" },
        { id: 2, nome: "Publicar" }
      ],

      bloqueiaBotoes: false,
      editar: true,
      files: [],
      carregando: true,
      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },
    getItemImagem() {
      return "item.imagem";
    },
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      if (!this.id && this.files.length == 0) {
        this.validacao.files = "Selecione pelo menos 1 arquivo PDF";
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      if (this.files.length) {
        this.onSubmit(novo);
        return;
      }

      if (!this.edicao.edicaoStatus || this.edicao.edicaoStatus == 3) {
        this.dtEntrada = null;
        this.dtEntradaUS = null;
        this.hsEntrada = null;
        this.hsEntradaUS = null;
      }
      var dtPublicacao = this.dtEntrada ? util.formatDateUS(this.dtEntrada) : "";
      if (dtPublicacao) {
        dtPublicacao += "T" + (this.hsEntrada ? this.hsEntrada : "00:00");
      }
      //* cria novo objeto para não mexer no objeto principal
      var edicaoCommit = {
        idEdicao: this.id,
        edicaoStatus: this.edicao.edicaoStatus.toString(),
        edicaoDataEntrada: dtPublicacao,
        cnpj: this.edicao.publicacaoLegal ? this.edicao.cnpj : "",
        publicacaoLegal: this.edicao.publicacaoLegal
      };

      this.isUpdating = true;
      try {
        //* Somente update, pois inclusão foi no submit acima com as imagens
        await api.post(process.env.VUE_APP_URL_WEB_API + "flip/alteraedicao", edicaoCommit);

        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/flip/edicao");
          this.$router.go("/flip/edicao");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/flip/edicoes");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    async assinar() {
      await util.limparObjeto(this.validacao, false);
      if (!this.id || !this.paginas.length) {
        this.validacao.files = "Selecione pelo menos 1 arquivo PDF";
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "flip/assinaPDF/" + this.id);

        this.mensagem = "Edição foi assinada";
        this.snackbar = true;
        this.$router.push("/flip/edicao/" + this.id);
        this.$router.go("/flip/edicao/" + this.id);
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    async onSubmit(novo) {
      if (this.files.length) {
        this.mensagem = "";
        this.isUpdating = true;
        for (let index = 0; index < this.files.length; index++) {
          const file = this.files[index];

          const formData = new FormData();
          formData.append("arquivoEdicao", file);
          formData.append("edicaoStatus", this.edicao.edicaoStatus.toString());
          formData.append("edicaoNumero", this.edicao.edicaoNumero);
          formData.append("edicaoNome", this.edicao.edicaoNome);
          formData.append("edicaoData", util.formatDateUS(this.dtEdicao));
          formData.append("cnpj", this.edicao.cnpj);
          formData.append("publicacaoLegal", this.edicao.publicacaoLegal);
          formData.append("paginasTotal", this.edicao.paginasTotal);
          if (!this.edicao.edicaoStatus || this.edicao.edicaoStatus == 3) {
            this.dtEntrada = null;
            this.dtEntradaUS = null;
            this.hsEntrada = null;
            this.hsEntradaUS = null;
          }

          var dtPublicacao = this.dtEntrada ? util.formatDateUS(this.dtEntrada) : "";
          if (dtPublicacao) {
            dtPublicacao += "T" + (this.hsEntrada ? this.hsEntrada : "00:00");
          }

          formData.append("edicaoDataEntrada", dtPublicacao);

          try {
            const retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "flip/inserepaginaedicao", formData);
            this.id = retorno.data.idEdicao;
            this.edicao._id = retorno.data.idEdicao;
            this.edicao.arquivosIndividuais = retorno.data.arquivosIndividuais;
            this.edicao.edicaoNomePasta = retorno.data.edicaoNomePasta;
            this.edicao.edicaoData = util.formatDateUS(this.dtEdicao);

            if (retorno.data.Paginas) {
              this.paginas = [];
              var ordem = 0;
              retorno.data.Paginas.forEach((element) => {
                element.imagem = `${process.env.VUE_APP_URL_WEB_API}arquivos/${this.idSitePrincipal}/flip/${this.dtEdicaoUS}/${this.edicao.edicaoNomePasta}/jpg/${
                  element.paginasNumero
                }.jpeg?${new Date().getTime()}`;
                ordem++;
                element.ordem = ordem;
                this.paginas.push(element);
              });
            }
          } catch (e) {
            this.mensagem +=
              (this.mensagem ? " | " : "") +
              (e.toString().includes("status code 400")
                ? "SESSÃO EXPIRADA"
                : e.response && e.response.data && e.response.data.length > 0
                ? e.response.data[0].mensagem
                : "Indeterminado, verifique sua conexão");
            this.snackErro = true;
          }
        }

        if (this.mensagem) {
          this.validacao.mensagemErro = this.mensagem;
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Dados foram atualizados";
          this.snackbar = true;
        }

        this.files = [];
        this.isUpdating = false;

        if (novo == "true") {
          this.$router.push("/flip/edicao");
          this.$router.go("/flip/edicao");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/flip/edicoes");
          }, 2000);
        }
      } else {
        this.mensagem = "Selecione um arquivo";
        this.snackErro = true;
      }
    },

    async subir(item) {
      var index = this.paginas.indexOf(item);
      var acima = index == 0 ? 0 : index - 1;
      if (index != acima) {
        this.paginas = await this.changePosition(this.paginas, index, acima);
      }
    },
    async descer(item) {
      var index = this.paginas.indexOf(item);
      var abaixo = index == this.paginas.length - 1 ? this.paginas.length - 1 : index + 1;
      if (index != abaixo) {
        this.paginas = await this.changePosition(this.paginas, index, abaixo);
      }
    },
    async excluir(item) {
      var edicaoCommit = {
        idEdicao: this.id,
        edicaoNomePasta: this.edicao.edicaoNomePasta,
        edicaoData: this.edicao.edicaoData,
        paginasNumero: item.paginasNumero
      };
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "flip/excluipaginaedicao", edicaoCommit);
        var index = this.paginas.indexOf(item);
        this.paginas.splice(index, 1);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async changePosition(arr, from, to) {
      var numA = arr[from].paginasNumero;
      var numB = arr[to].paginasNumero;

      var edicaoCommit = {
        idEdicao: this.id,
        edicaoData: this.edicao.edicaoData,
        edicaoNomePasta: this.edicao.edicaoNomePasta,
        paginaA: {
          id: arr[from].paginasId,
          paginasNumero: numB
        },
        paginaB: {
          id: arr[to].paginasId,
          paginasNumero: numA
        }
      };

      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "flip/alteraposicaopagina", edicaoCommit);

        arr[from].paginasNumero = numB;
        arr[to].paginasNumero = numA;

        arr.splice(to, 0, arr.splice(from, 1)[0]);
        var ordem = 0;
        arr.forEach((element) => {
          ordem++;
          element.ordem = ordem;
        });
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }

      return arr;
    },
    async buscaNumeroEdicao() {
      this.edicao.edicaoNome = util.removeAcentos(this.edicao.edicaoNome);
      this.edicao.edicaoNome = util.pLetra(this.edicao.edicaoNome);
      this.edicao.edicaoNome = this.edicao.edicaoNome.toUpperCase();
      this.validacao.edicaoNome = false;
      if (this.edicao.edicaoNome && this.dtEdicao && !this.edicao.edicaoNumero) {
        if (this.$store.state.usuario.site_nroEdicaoFlip == "DDMMAAAA") {
          this.edicao.edicaoNumero = this.dtEdicao.replace("/", "");
        } else {
          try {
            const retorno = await api.get(
              process.env.VUE_APP_URL_WEB_API + "flip/existeedicao?edicaoNome=" + this.edicao.edicaoNome + "&edicaoData=" + util.formatDateUS(this.dtEdicao)
            );
            if (retorno.data.edicaoNumero) {
              this.edicao.edicaoNumero = retorno.data.edicaoNumero;
            }
          } catch (e) {
            this.mensagem =
              "ERRO: " +
              (e.toString().includes("status code 400")
                ? "SESSÃO EXPIRADA"
                : e.response && e.response.data && e.response.data.length > 0
                ? e.response.data[0].mensagem
                : "Indeterminado, verifique sua conexão");
            this.snackErro = true;
          }
        }
      }
    },
    defineDtEntrada() {
      if (this.edicao.edicaoStatus == 1 || this.edicao.edicaoStatus == 2) {
        if (!this.dtEntrada) {
          this.dtEntrada = this.dtEdicao;
          this.dtEntradaUS = this.dtEdicaoUS;
        }
      } else {
        this.dtEntrada = null;
        this.dtEntradaUS = null;
        this.hsEntrada = null;
        this.hsEntradaUS = null;
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    valida_data(date) {
      return util.valida_data_BR(date);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    valida_cnpj(text) {
      if (text.length != 14) {
        return "Identificação deve ter 14 dígitos";
      } else {
        return util.valida_cnpj(text) ? true : "CNPJ inválido";
      }
    },

    formatDateUS(date) {
      return util.formatDateUS(date);
    }
  },
  watch: {
    dtEdicaoUS() {
      this.dtEdicao = util.formatDate(this.dtEdicaoUS);
      this.mnuDtEdicao = false;
    },
    dtEntradaUS() {
      this.dtEntrada = util.formatDate(this.dtEntradaUS);
      this.mnuDtEntrada = false;
    },
    hsEntradaUS() {
      this.hsEntrada = this.hsEntradaUS;
      this.mnuHsEntrada = false;
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach((element) => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FLIP_EDIÇÕES_CRIAR") {
        this.direitoCriar = true;
      }
    });

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    try {
      //* Se é edição, carrega o item
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FLIP_EDIÇÕES_CRIAR"]);
        var dtAmanha = new Date();
        dtAmanha.setDate(dtAmanha.getDate() + 1);
        this.dtEdicao = util.formatDate(dtAmanha);
        this.dtEdicaoUS = util.dateToStringUS(dtAmanha);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FLIP_EDIÇÕES_EDITAR"]);

        //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "flip/buscadadosedicao?idEdicao=" + this.id);

        if (!response.data || !response.data.dadosEdicao || response.data.dadosEdicao.length == 0) {
          this.$router.push("/flip/edicoes");
        } else {
          this.edicao = response.data.dadosEdicao[0];

          var dtLimite = new Date();
          dtLimite.setDate(dtLimite.getDate() - 1);
          dtLimite = util.parseDateUS(dtLimite);
          if (this.edicao.edicaoDataEntrada && util.parseDateUS(this.edicao.edicaoDataEntrada) < dtLimite) {
            this.editar = false;
          }
          this.edicao.edicaoStatus = parseInt(this.edicao.edicaoStatus);
          this.dtEdicaoUS = this.edicao.edicaoData;
          this.dtEntradaUS = this.edicao.edicaoDataEntrada;
          var hora = this.edicao.edicaoDataEntrada;
          if (hora) {
            if (hora.includes("T")) {
              hora = hora.split("T")[1].substring(0, 5);
            } else if (hora.includes(" ")) {
              hora = hora.split(" ")[1].substring(0, 5);
            } else {
              hora = "00:00";
            }
            this.hsEntradaUS = hora;
          } else {
            this.hsEntradaUS = "";
          }

          this.paginas = [];
          var ordem = 0;
          this.edicao.Paginas.forEach((element) => {
            element.imagem = `${process.env.VUE_APP_URL_WEB_API}arquivos/${this.idSitePrincipal}/flip/${this.edicao.edicaoData}/${this.edicao.edicaoNomePasta}/jpg/${
              element.paginasNumero
            }.jpeg?${new Date().getTime()}`;
            ordem++;
            element.ordem = ordem;
            this.paginas.push(element);
          });

          this.carregando = false;
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
